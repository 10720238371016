import Home from "./Main/Home";
import About from "./Main/About";
import Projects from "./Main/Projects";
import Contact from "./Main/Contact";
const Main = () => {
	  return (
		<main>
			<Home/>
			<About/>
			<Projects/>
			<Contact/>
		</main>

  );
}
export default Main;