export const navLinks = [
	{
		navLinkId: "Home",
		scrollToId: "homeCont"
	},
	{
		navLinkId: "About",
		scrollToId: "aboutCont",
	},
	{
		navLinkId: "Projects",
		scrollToId: "projectsCont",
	},
	{
		navLinkId: "Contact",
		scrollToId: "contactCont",
	}

]
